function convertDateTimeToUSAFormat(isoDateString){

    // Створюємо об'єкт Date
    const date = new Date(isoDateString);

    // Налаштування для часової зони Нью-Йорка (Eastern Time)
    const options = {
        timeZone: 'America/New_York', // Часовий пояс Нью-Йорка
        hour: 'numeric',              // Години
        minute: 'numeric',            // Хвилини
        hour12: true                  // Використовувати 12-годинний формат (AM/PM)
    };

    // Форматуємо дату з урахуванням часової зони
    const formatter = new Intl.DateTimeFormat('en-US', options);

    return formatter.format(date);
}

module.exports = {
    convertDateTimeToUSAFormat,
};
