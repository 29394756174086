$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});

window.status = 'create';
$form_response =null;
var nameEventLabel= '';
var nameEventCategory = '';
// Introductory Lessons

$(document).on('click','.introductory-item', function () {
    $('.introductory-item').each(function () {
        $(this).removeClass('active')
        $(this).addClass('not_active')
    })
    $(this).addClass('active')
    $(this).removeClass('not_active')
    $('.introductory-item.not_active').each(function () {
        $(this).hide();
    })
    $('#app_name').html($(this).find('.introductory-item__title').html())
    $('input[name="appointmentTypeID"]').val($(this).find('.choose-date').data('id'));
    $('.choose_another').show()
    if ((typeof $(this).data('child_appointment') !== 'undefined') && ($(this).data('child_appointment') === 1)) {
        $('.introductory-info__child').show();
    }
    localStorage.setItem('AppName', $(this).find('h5').html());
    localStorage.setItem('AppAmount', parseFloat($(this).find('.introductory-item__price--default').html().replace('$','')));
    if (localStorage.getItem('AppAmount')>0) {
        let cost = localStorage.getItem('AppAmount');
        cost = Number(cost).toFixed(2);
        $('#buttons').html('<button type="button" id="pay" class="btn btn-secondary btn-block">Pay $'+cost+'</button>');
        $('#pay').addClass('validate-btn');
        $('#pay').click( function () {
           nameEventCategory = $('#eventCategory').attr('value')
           nameEventLabel = $('#eventLabel').attr('value')

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(null);

            window.dataLayer.push({
                'eventName': 'form_submission_no_pay',
                'event':'lead',
                'eventLabel': nameEventLabel,
                'eventCategory':nameEventCategory,
            });
            $('input[name="payed"]').val(0);
            $("#form_schedule").submit()
            var amount = localStorage.getItem('AppAmount');
            var closePopUp = true;
            var handler = StripeCheckout.configure({
                key: process.env.MIX_STRIPE_KEY,
                locale: 'us',
                token: function (token) {
                    // You can access the token ID with `token.id`.
                    // Get the token ID to your server-side code for use.
                    $('#token_response').html(JSON.stringify(token));
                    closePopUp = false;

                    $.ajax({
                        url: '/stripe',
                        method: 'post',
                        data: { tokenId: token.id, amount: amount },
                        success: (response) => {

                            $('#buttons').html('<button type="submit" class="btn btn-primary create" id="send_form">Complete Appointment</button>')
                            $('input[name="payed"]').val(1)
                            $('a[href="#app"]').hide()


                            $.ajax({
                                url: '/api/appointment/pay',
                                type: "POST",
                                data: {
                                    id : $form_response.id,
                                    amount: localStorage.getItem('AppAmount'),
                                },
                                success: function( response ) {
                                    var appointmentId = $form_response.id;
                                    $form_response = response;
                                    window.status = 'edit'
                                    $('#send_form.create').html('Submit');

                                    $('#res_message').show();
                                    $('#res_message').html($form_response.msg);
                                    $('#msg_div').removeClass('d-none');
                                    $('#cancel_button').attr('href', '/api/appointment/cancel/'+appointmentId)
                                    localStorage.setItem('selected_id',appointmentId)
                                    $('input[name="selected_id"]').val(appointmentId)
                                    // document.getElementById("form_schedule.create").reset();
                                    $('.introductory-tabs__item').each(function () {
                                        $(this).removeClass('is-show')
                                    })
                                    $('#confirm').addClass('is-show')
                                    $('#confirm .introductory-name').html($form_response.firstName+' '+$form_response.lastName)
                                    $('#confirm .introductory-description').html($form_response.type)
                                    $('#confirm .introductory-text.first').html($form_response.date+' '+$form_response.time)
                                    $('#confirm .introductory-text.second').html($form_response.calendar)
                                    $('.google_button').attr('href','https://www.google.com/calendar/render?action=TEMPLATE&text='+encodeURIComponent($form_response.type)+'&dates='+encodeURIComponent(moment($form_response.datetime).toISOString()+'/'+moment($form_response.datetime).add($form_response.duration, 'minutes').toISOString())+'&location=&details=%0A%0AView%2FChange+Appointment%3A%0A'+encodeURIComponent($form_response.confirmationPage)+'%0A%0A%28created+by+Acuity+Scheduling%29%0AAcuityID%3D328424079%0AGoogle&sf=true&output=xml')
                                    $('.acuity_button').attr('href',$form_response.confirmationPage)
                                    setTimeout(function(){
                                        $('#res_message').hide();
                                        $('#msg_div').hide();
                                    },10000);


                                }
                            })

                        },
                        error: (error) => {
                            console.log(error);
                        }
                    })
                },
                closed: function () {
                    if ($form_response && $form_response.id && closePopUp) {
                        $.ajax({
                            url: '/api/appointment/ajax-cancel/'+$form_response.id,
                            type: "GET"
                        })
                    }
                }
            });

            handler.open({
                name: localStorage.getItem('AppName'),
                amount: amount * 100
            });
        })
    }
    else {
        $('#buttons').html('<button type="submit" class="btn btn-primary create" id="send_form">Complete Appointment</button>');
    }
})

$(document).on('click','.choose_another', function () {
    $(this).hide();
    $('.introductory-info__child').hide();
    $('a[href="#info"]').hide();
    $('.introductory-item.not_active').each(function () {
        $(this).show();
    })
    $('.introductory-item').each(function () {
        $(this).find('input').val('')
        $(this).find('select.select').removeClass('is-show')
        $(this).find('select.select').val('')
        localStorage.removeItem('selected_item')
        localStorage.removeItem('selected_date')
    })
})
$(document).on('change', '.choose-date', function () {
    var date = moment($(this).val(),'M/D/YYYY').format('D.M.YYYY');
    var times ='';
    var $this = $(this);
    $('input[name="appointmentTypeID"]').val($this.data('id'));
    if($this.data('id') && date){
        localStorage.setItem('selected_item', $this.data('id'))
        localStorage.setItem('selected_date', date)
    }else{
        localStorage.removeItem('selected_item')
        localStorage.removeItem('selected_date')
    }

    $.ajax({
        method: "GET",
        url: "/api/availability/times",
        data: { id: $(this).data('id'),date: date}
    })
        .done(function( data ) {
            if($this.val() != "") {
                $('a[href="#info"]').hide();
                $this.closest('.introductory-item__form').find('.select-time').addClass('is-show');
                var i=0;
                var {convertDateTimeToUSAFormat} = require('./helpers/dateTimeToUSAFormat.js');
                $.each(data, function () {
                    if (i<1) {
                        $('input[name="datetime"]').val($(this)[0].time);
                        i++
                    }
                    times += '<option class="select-btn" data-time="'+$(this)[0].time+'" value="'+$(this)[0].time+'" >'+convertDateTimeToUSAFormat($(this)[0].time)+'</option>'
                });
                $this.closest('.introductory-item__form').find('.select-time').html(times);
                $this.closest('.introductory-item__form').find('.select-time').prepend($('<option>', {
                    value: "",
                    text : "Select Time",
                    selected: true,
                    disabled: true
                }));
            }
        });
});

$(document).on('change', '.select-time', function () {
    $('input[name="datetime"]').val(this.value);
  var eventCategoryName =  $(this).closest('.introductory-item__form--item').find('.eventCategory_current').val();
    var eventLabelName =   $(this).closest('.introductory-item__form--item').find('.eventLabel_current').val();

     $('#eventCategory').attr('value', eventCategoryName)
     $('#eventLabel').attr('value', eventLabelName)

    if ($(this).val() !== "") {
        $('a[href="#info"]').show();
    }  else {
        $('a[href="#info"]').hide();
    }
});

$(document).on('click', '.input-radio-hear', function () {
    $('input[name="hear"]').val(this.value);
});
$(document).on('click', '.input-radio-child-age', function () {
    $('input[name="child_age"]').val(this.value);
});

$(function () {
    $('a[href="#info"]').hide();
    $('.choose_another').hide()
    $('.choose-time').each(function () {
        $(this).hide();
    })
    $.datetimepicker.setLocale('en');
    $.datetimepicker.setDateFormatter({
        parseDate: function (date, format) {
            var d = moment(date, 'M/D/YYYY');
            return d.isValid() ? d.toDate() : false;
        },
        formatDate: function (date, format) {
            return moment(date).format('M/D/YYYY');
        },
    });

    $('.input-date').each(function () {
        var dates = $(this).data('dates');
        var avail = [];
        var i=0;
        if (dates !== undefined && dates.length > 0) {
            dates = dates.split(', ');

            dates.forEach(function (date) {
                if (date !== undefined) {
                    avail[i] = moment(date).format('M/D/YYYY')
                    i++
                }

            })
        } else {
            $(this).closest('.col-lg-6').remove();
        }
        $(this).datetimepicker({
            timepicker: false,
            allowDates: avail,
            formatDate: 'M.D.YYYY',
            scrollMonth : false,
            scrollInput : false
        })
    })
});

if ($("#form_schedule.create").length > 0) {
    $("#form_schedule.create").validate({

        rules: {
            datetime: {
                required: true,
            },
            hear: {
                required: true,
            },
            hear2: {
                required: true,
            },
            appointmentTypeID: {
                required: true,
            },
            firstName: {
                required: true,
            },
            lastName: {
                required: true,
            },
            phone: {
                required: true,
                digits:true,
                minlength: 6,
                maxlength:12,
            },
            email: {
                required: true,
                maxlength: 50,
                email: true,
            },
            child_name: {
                required: "Please choose how did you hear about us"
            },
            child_age: {
                required: "What keyword search did you use?"
            },
        },
        messages: {

            datetime: {
                required: "Please choose date",
            },
            appointmentTypeID: {
                required: "Please choose appointment",
            },
            hear: {
                required: "Please choose how did you hear about us",
            },
            hear2: {
                required: "What keyword search did you use?",
            },
            firstName: {
                required: "Please enter First Name",
            },
            lastName: {
                required: "Please enter First Name",
            },
            phone: {
                required: "Please enter contact number",
                minlength: "The contact number should be 6 digits",
                digits: "Please enter only numbers",
                maxlength: "The contact number should be 12 digits",
            },
            email: {
                required: "Please enter valid email",
                email: "Please enter valid email",
                maxlength: "The email name should less than or equal to 50 characters",
            },
            child_name: {
                required: "What is your child's name?"
            },
            child_age: {
                required: "Please age your child"
            },

        },
        submitHandler: function(form) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $('#send_form.create').html('Sending..');
            $.ajax({
                url: window.status === 'create'?'/api/appointment/send':'/api/appointment/edit' ,
                type: "POST",
                data: $('#form_schedule.create').serialize(),
                success: function( response ) {
                 $form_response = response;



                    let appAmount = localStorage.getItem('AppAmount');
                    if (isNaN(appAmount)) {

                        nameEventCategory = $('#eventCategory').attr('value')
                        nameEventLabel = $('#eventLabel').attr('value')

                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push(null);

                        window.dataLayer.push({
                            'eventName': 'form_submission_no_pay',
                            'event':'lead',
                            'eventLabel': nameEventLabel,
                            'eventCategory':nameEventCategory,
                        });

                     window.status = 'edit'
                     $('#send_form.create').html('Submit');
                     if ($('input[name="payed"]').val() == 1) {
                         $.ajax({
                             url: '/api/appointment/pay',
                             type: "POST",
                             data: {
                                 id : response.id,
                                 amount: localStorage.getItem('AppAmount'),
                             },
                         })
                     }
                     $('#res_message').show();
                     $('#res_message').html(response.msg);
                     $('#msg_div').removeClass('d-none');
                     $('#cancel_button').attr('href', '/api/appointment/cancel/'+response.id)
                     localStorage.setItem('selected_id',response.id)
                     $('input[name="selected_id"]').val(response.id)
                     // document.getElementById("form_schedule.create").reset();
                     $('.introductory-tabs__item').each(function () {
                         $(this).removeClass('is-show')
                     })
                     $('#confirm').addClass('is-show')
                     $('#confirm .introductory-name').html(response.firstName+' '+response.lastName)
                     $('#confirm .introductory-description').html(response.type)
                     $('#confirm .introductory-text.first').html(response.date+' '+response.time)
                     $('#confirm .introductory-text.second').html(response.calendar)
                     $('.google_button').attr('href','https://www.google.com/calendar/render?action=TEMPLATE&text='+encodeURIComponent(response.type)+'&dates='+encodeURIComponent(moment(response.datetime).toISOString()+'/'+moment(response.datetime).add(response.duration, 'minutes').toISOString())+'&location=&details=%0A%0AView%2FChange+Appointment%3A%0A'+encodeURIComponent(response.confirmationPage)+'%0A%0A%28created+by+Acuity+Scheduling%29%0AAcuityID%3D328424079%0AGoogle&sf=true&output=xml')
                     $('.acuity_button').attr('href',response.confirmationPage)
                     setTimeout(function(){
                         $('#res_message').hide();
                         $('#msg_div').hide();
                     },10000);
                 }

                }
            });
        }
    })
}

if ($("#register").length > 0) {
    $("#register").validate({
        rules: {
            first_name: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                maxlength: 50,
                customEmail: true
            },
            password: {
                required: true,
                minlength: 8,
                noSequentialChars: true
            },
            password_confirmation: {
                required: true,
                minlength: 8,
                equalTo: '#password'
            },
            accept_terms: {
                required: true,
            }
        },
        messages: {
            first_name: {
                required: "Please enter your name",
                minlength: "The name must be at least 2 characters",
            },
            email: {
                required: "Please enter your email address",
                maxlength: "The email name should less than or equal to 50 characters",
                customEmail: "Please enter a valid email address",
            },
            password: {
                required: "Password is required",
                minlength: "The password must be at least 8 characters",
                noSequentialChars: "The password cannot have sequential characters of 3 or more"
            },
            password_confirmation: {
                required: "Password confirmation is required",
                minlength: "The password confirmation must be at least 8 characters",
                equalTo : 'The password confirmation does not match',
            },
            accept_terms: {
                required: "Please accept the terms of use and privacy policy",
            }
        }
    });
}

if ($("#login").length > 0) {
    $("#login").validate({
        rules: {
            email: {
                required: true,
                maxlength: 50,
                customEmail: true
            },
            password: {
                required: true
            }
        },
        messages: {
            email: {
                required: "Please enter your email address",
                maxlength: "The email should be less than or equal to 50 characters",
                customEmail: "Please enter a valid email address"
            },
            password: {
                required: "Password is required"
            }
        }
    });
}

if ($("#reset").length > 0) {
    $("#reset").validate({
        rules: {
            email: {
                required: true,
                maxlength: 50,
                customEmail: true
            },
            password: {
                required: true,
                minlength: 8,
                noSequentialChars: true
            },
            password_confirmation: {
                required: true,
                minlength: 8,
                equalTo: '#password'
            }
        },
        messages: {
            email: {
                required: "Please enter your email address",
                maxlength: "The email name should less than or equal to 50 characters",
                customEmail: "Please enter a valid email address",
            },
            password: {
                required: "Password is required",
                minlength: "The password must be at least 8 characters",
                noSequentialChars: "The password cannot have sequential characters of 3 or more"
            },
            password_confirmation: {
                required: "Password confirmation is required",
                minlength: "The password confirmation must be at least 8 characters",
                equalTo : 'The password confirmation does not match',
            }

        }
    });
}

jQuery.validator.addMethod("customEmail", function(value, element) {
    return this.optional(element) || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
}, "Please enter a valid email address.");

jQuery.validator.addMethod("noSequentialChars", function(value, element) {
    var sequentialCharsRegex = /(.)\1{2,}/;
    return !sequentialCharsRegex.test(value);
}, "The password cannot have sequential characters of 3 or more.");

$(document).on('change, keyup', '#form_schedule input', function () {

    checkValid()
});

function checkValid() {
    $('#send_form').hide();
    $('#pay').addClass('validate-btn');
    if($('input[name="firstName"]').val() && $('input[name="lastName"]').val() && validNumber() && $('input[name="email"]').val() && $('input[name="hear"]').val() && $('input[name="hear2"]').val() && $('input[name="datetime"]').val()) {
        $('#send_form').show();
        $('#pay').removeClass('validate-btn');
    }
}


function validNumber() {
    const re = /^\d*(\.\d+)?$/;
    if( $('input[name="phone"]').val().length > 6 && $('input[name="phone"]').val().length <= 12 && $('input[name="phone"]').val().match(re)){
        return true;
    }else{
        return false;
    }
}
